.token-info-section {

    .token-header-row {
        flex-wrap: wrap;
    }

    .token-symbol {
        input.input {
            width: 64px;
            flex: 1;
        }

        .base-denom-suffix {
            margin-top: -3px;
            font-size: 12px;
            font-weight: bold;
            color: var(--text-color);
        }
    }

    .no-inflation-alert {
        margin-top: -16px;
    }

    .total-supply {
        input.input {
            width: 64px;
            flex: 1;
        }

        .total-supply-suffix {
            font-size: 12px;
            font-weight: bold;
            color: var(--text-color);
        }
    }

    .token-factory-switch-container {
        margin: 20px 0 0;

        .token-factory-switch {
            width: fit-content;
        }
    }

    .control-switch-container {
        flex: initial;

        &:not(:last-of-type) {
            margin-right: 24px;
        }

        .control-switch {
            margin: 4px 0;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .token-info-section {
        .control-switch-container {
            width: 100%;

            .control-switch {
                margin: 0;
            }

            .space {
                flex: initial;
            }
        }

        .image-uploader-container.control-container {
            margin-right: 0;

            .control-label-container {
                width: 100%
            }

            .image-uploader {
                width: max(100%, 256px);
                height: 256px;

                .image-preview.circle {
                    width: 256px;
                }
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .token-info-section .image-uploader-container {
        align-items: center;
    }
}

