.manage-rollapps {
    .create-first-rollapp-section {
        height: 300px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        padding: var(--page-large-padding);
        border: 1px dashed rgba(var(--cream-rgb), 0.5);
        border-radius: 6px;
        overflow: hidden;
        margin: 8px 0 42px;

        .create-first-rollapp-background {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }

        .create-first-rollapp-content {
            left: var(--page-padding);
            max-width: 400px;

            .description {
                margin: 8px 0 24px;
                line-height: 24px;
            }

            .start-button {
                width: 120px;
            }
        }
    }

    .current-created-rollapp.section {
        padding: 32px;

        p {
            margin-top: 8px;
            font-size: 16px;
            line-height: 24px;
        }

        .generate-options {
            margin-top: 32px;


            .manual-generate {
                border-top: 1px dashed var(--border-color-light);
                padding-top: 24px;
                flex: 1;
                max-width: 600px;
            }

            .generate-option {
                padding: 24px;
                justify-content: center;
                align-items: center;
                display: flex;
                flex: 1;

                &:not(:first-of-type) {
                    border-left: 1px dashed var(--border-color-light);
                }
            }

            .genesis-file-path-input {
                height: 40px;
            }
        }
    }

    .rollapps-table-actionbar {
        margin: 24px 0 12px;
        line-height: 36px;
        align-items: center;
        display: flex;

        .table-header {
            flex: 1;
        }

        .manage-rollapp-button {
            margin-left: 16px;
        }

        .create-rollapp-button-text {
            margin-left: 4px;
        }
    }

    .managed-rollapps-table-container {
        overflow: auto;

        .table {
            --row-height: 72px;

            .alias-column .alias-link {
                font-weight: bold;
                font-style: italic;
            }

            .actions-column .manage-rollapp-action {
                margin-right: 8px;
            }

            .creation-step-done {
                margin-left: 8px;
            }

            .vm-logo {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }

        .no-data {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px 0;
            color: var(--text-color-secondary);
        }
    }
}


@media only screen and (max-width: 1024px) {
    .manage-rollapps {
        .rollapps-table-actionbar .create-rollapp-button-text {
            display: none;
        }

        .create-first-rollapp-section {
            height: fit-content;
            align-items: initial;
            padding: var(--page-large-padding) var(--page-padding);
            background-image: linear-gradient(to bottom right, var(--black), rgba(var(--black-rgb), 0.8) 75%, transparent);
        }
    }
}

@media only screen and (max-width: 720px) {
    .managed-rollapps-table-container .table {
        font-size: 12px;
    }
}

