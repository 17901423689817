.account-stake {
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 8px 0;

    .delegations-loader {
        margin: 8px auto;
    }

    .delegation-row {
        display: flex;
        height: 48px;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;

        .validator-logo {
            width: 28px;
            height: 28px;
            min-width: 28px;
            border-radius: 14px;
            overflow: hidden;
            object-fit: cover;
            margin-right: 6px;

            &.fallback {
                background-color: var(--cream);
                padding: 4px;
            }
        }

        .validator-name {
            font-size: 14px;
            font-weight: 600;
            max-width: 120px;
            overflow: hidden;
            word-break: break-word;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .delegation-amount {
            display: block;
            flex-direction: column;
            flex: 1;
            text-align: right;
            font-weight: 600;
            margin-left: 8px;
            font-size: 14px;
            white-space: nowrap;
        }

        .delegation-value {
            font-size: 12px;
            margin-top: 2px;
            color: var(--text-color-secondary);
            display: block;
            line-height: 12px;
        }

        &:hover {
            background-color: rgba(var(--cream-rgb), 0.05);
        }
    }

    .new-stake-button {
        margin: 8px auto;
        display: flex;
    }
}

.account-menu-actions.claim-rewards {

    .claimable-rewards-property {
        min-width: 50%;
        padding-right: 12px;
        margin-right: 4px;

        .property-value {
            margin-top: 0;
        }
    }
}
