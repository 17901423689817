.claimable-transfers-link {
    text-align: center;
    border-bottom: 1px solid var(--border-color-dark);
    display: block;
    margin-top: -8px;
    margin-bottom: 8px;
}

.error-label.unavailable-endpoints {
    padding: 8px 16px;
    text-align: center;
    border-bottom: 1px solid var(--border-color-dark);
    margin-bottom: 0;
}
