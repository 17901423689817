.account-pools {
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 8px 0;

    .positions-loader {
        margin: 8px auto;
    }

    .position-row {
        display: flex;
        height: 48px;
        align-items: center;
        padding: 0 16px;
        cursor: pointer;

        .asset-logo {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            object-fit: cover;
            z-index: 1;

            &:last-of-type {
                z-index: 0;
                margin-left: -8px;
            }
        }

        .pool-assets-names {
            display: flex;
            margin-left: 6px;
            white-space: nowrap;
            max-width: 120px;
            align-items: center;
        }

        .position-amount {
            display: block;
            flex-direction: column;
            flex: 1;
            text-align: right;
            font-weight: 600;
            margin-left: 8px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .bonded-value {
            font-size: 12px;
            margin-top: 2px;
            color: var(--text-color-secondary);
            display: block;
            line-height: 12px;
        }

        &:hover {
            background-color: rgba(var(--cream-rgb), 0.05);
        }
    }

    .no-positions {
        text-align: center;
        display: block;
        padding: 8px 0;
        color: var(--text-color-secondary);
    }
}



