.send-funds-dialog.send-funds-dialog {
    width: 480px;
    display: flex;
    flex-direction: column;

    .address-input-label {
        display: block;
        margin: 24px 0 12px;
    }

    .send-warning {
        margin-top: -8px;
        margin-bottom: 24px;
    }
}
