.quick-auth-action {
    cursor: pointer;
    position: fixed;
    bottom: 12px;
    right: 12px;
    height: 36px;
    min-width: 36px;
    opacity: 0.8;
    background-color: var(--black);
    border-radius: 8px;
    border: 1px solid var(--orange);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px 2px rgba(var(--orange-rgb), 0.4);

    .thunder-icon {
        --color: var(--orange);

        display: block;
        width: 16px;
        height: 16px;
    }

    .time {
        flex: 1;
        text-align: center;
    }

    &.connected {
        min-width: 100px;
        padding: 0 8px 0 6px;
        justify-content: initial;
    }
}

@media only screen and (max-width: 1024px) {
    .quick-auth-action {
        bottom: calc(var(--footer-height) + 12px);
    }
}
