.holder-list-container {
    --row-height: 42px;

    overflow: auto;
    max-height: calc(10 * var(--row-height));

    .holder-list {
        width: 100%;

        .holder-row {
            transition: opacity 300ms ease-in-out;
        }
    }

    .address-badge {
        font-weight: bold;
    }

    .no-data {
        display: flex;
        height: 64px;
        align-items: center;
        justify-content: center;
        color: var(--text-color-secondary);
    }
}


@media only screen and (max-width: 720px) {
    .holder-list-container .holder-list {
        font-size: 12px;
    }
}


