.hub-wallet-selector-dialog.hub-wallet-selector-dialog {
    width: 720px;

    .description {
        color: var(--text-color-secondary);
        margin-top: 12px;
    }

    .connection-groups {
        display: flex;
        margin-top: 24px;
        padding: 24px 0 16px;
        border-top: 1px solid var(--border-color);

        .connection-options-group {
            flex: 1;

            .group-title {
                display: flex;
                align-items: center;
            }

            .recommended-subtitle {
                color: var(--text-color-secondary);
                margin-bottom: 8px;
                display: block;
            }

            .connection-button {
                margin-top: 16px;
                width: 100%;
                justify-content: flex-start;

                .connection-icon {
                    margin-right: 12px;

                    svg {
                        max-width: 28px;
                        max-height: 28px;
                        min-width: 28px;
                        min-height: 28px;
                    }

                    &.quick-auth {
                        --color: var(--orange);
                    }
                }

                .divider {
                    margin: 0 auto;
                    height: 100%;
                    width: 0;
                    border-right: 1px solid var(--border-color);
                }
            }

            &:not(:last-of-type) {
                padding-right: 24px;
                border-right: 1px solid var(--border-color);
                margin-right: 24px;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .hub-wallet-selector-dialog.hub-wallet-selector-dialog .connection-groups {
        flex-direction: column;

        .connection-options-group:not(:last-of-type) {
            padding-right: 0;
            border-right: none;
            margin-right: 0;
            margin-bottom: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid var(--border-color);
        }
    }
}
