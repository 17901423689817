.rollapps-page.page {
    margin-top: calc(var(--page-padding) * -1);

    .scrolling-trade-pairs {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 2;
    }
}

.rollapps-actionbar {
    margin-bottom: 24px;
    line-height: 36px;
    align-items: center;
    display: flex;

    .common-status {
        color: var(--text-color-secondary);
        padding: 0 16px;

        .iro-common-status-tooltip {
            max-width: 420px;
        }

        &.hover {
            color: var(--text-color);
            font-weight: bold;
        }
    }

    .filter-switch.button {
        padding: 16px 8px;
        border-radius: 8px;
        white-space: nowrap;

        svg path {
            transition: fill 150ms ease-in-out;
        }

        &.active {
            --button-color: rgba(var(--light-blue-rgb), 0.1);

            &.fair-launch {
                svg path {
                    --button-text-color: var(--orange);
                }
            }
        }

        &:not(:last-of-type) {
            margin-right: 4px;
        }
    }

    .switch-filters {
        display: flex;
        align-items: center;
        margin-left: 8px;
    }

    .rollapp-tag-switches {
        position: relative;
        margin: 0 12px 0 8px;
        padding-left: 8px;
        display: flex;

        .tags-selector {
            padding-left: 6px;
            padding-right: 6px;

            .more-label {
                color: var(--text-color);
            }

            &.active {
                --button-color: rgba(var(--light-blue-rgb), 0.1);
            }
        }

        &:before {
            content: '';
            height: 24px;
            width: 1px;
            background-color: var(--border-color-light);
            display: block;
            position: absolute;
            left: 0;
            top: calc(50% - 12px);
        }
    }

    .rollapps-actions {
        display: flex;
        align-items: center;

        .deploy-rollapp-button {
            margin-left: 12px;

            .deploy-rollapp-button-text {
                margin-left: 4px;
            }
        }

        .view-mode {
            margin-left: 12px;

            .button {
                --button-text-color: var(--text-color-secondary);
                --button-padding: 4px 12px;
                --button-border-radius: 6px;

                &.hover {
                    --button-text-color: var(--text-color);
                }
            }
        }

        .sort-selector {
            flex: 0;
            border-bottom-right-radius: var(--control-broder-radius);
            border-top-right-radius: var(--control-broder-radius);

            .trigger-arrow {
                display: none;
            }
        }
    }
}

.rollapps-page-cta {
    margin-bottom: 24px;
}

@media only screen and (min-width: 1921px) {
    .rollapps-page.page {
        max-width: 1920px;
    }
}

@media only screen and (min-width: 1025px) {
    .rollapps-page-footer {
        display: none;
    }
}

@media only screen and (max-width: 1280px) {
    .rollapps-actionbar {
        margin-top: 20px;
        flex-direction: column-reverse;
        align-items: start;

        .controls {
            flex: 1;
        }
    }

    .rollapps-actions {
        width: 100%;
        margin-bottom: 8px;
    }
}

@media only screen and (max-width: 1024px) {
    .rollapps-page.page {
        padding-bottom: calc(var(--page-padding) + 24px);

        .scrolling-trade-pairs {
            bottom: var(--footer-height);
        }
    }
}

@media only screen and (max-width: 720px) {
    .rollapps-actionbar {
        .rollapps-actions {
            .deploy-rollapp-button {
                margin-left: 8px;

                .deploy-rollapp-button-text {
                    display: none;
                }
            }

            .view-mode {
                margin-left: 8px;

            }
        }

        .rollapp-button-filters {
            flex-direction: column-reverse;
            width: 100%;

            .switch-filters {
                width: 100%;
                margin-left: 0;
                margin-bottom: 8px;
            }

            .common-status-buttons {
                width: 100%;
            }
        }
    }
}
