.create-proposal.create-proposal {
    .unsupported-wallet-message {
        margin: var(--page-small-padding) 0 var(--page-padding);
    }

    .proposal-parts {
        display: flex;
        margin-top: var(--page-small-padding);

        .proposal-details-part {
            flex: 5;
            margin-right: var(--page-small-padding);
        }

        .proposal-type-part {
            flex: 4;
            height: fit-content;
        }

        .proposal-section-header {
            margin-bottom: var(--page-padding);
        }
    }

    .proposal-description-markdown {
        min-height: 121.5px;
        max-height: 321.5px;
        border-radius: 4px;
        padding: 0 10px;
        border: 1px solid var(--border-color);
        color: var(--text-color-secondary);
    }

    .proposal-control-label {
        font-size: 14px;
        display: flex;
        margin: 24px 0 8px;
        align-items: center;
        justify-content: space-between;

        &:first-of-type {
            margin-top: 0;
        }
    }

    .proposal-type-selector {
        width: 100%;
    }

    .confirm-section {
        margin-top: var(--page-small-padding);

        .confirm-button {
            width: 100%;
        }
    }

    &:not(.expanded) {
        max-width: 800px;
    }
}

@media only screen and (max-width: 1024px) {
    .create-proposal.create-proposal {
        width: 100%;

        .proposal-parts {
            flex-direction: column;

            .proposal-details-part, .proposal-type-part {
                flex: 1;
                margin-right: initial;
            }

            .proposal-details-part {
                margin-bottom: var(--page-small-padding);
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .create-proposal .proposal-control-label {
        margin: 16px 0 8px;
    }
}
