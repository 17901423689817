.ibc-transfer-status-loader {
    margin: 64px auto;
}

.not-found-message {
    color: var(--text-color-secondary);
    text-align: center;
    padding: var(--page-large-padding);
}

.ibc-transfer-status-header {
    margin: 24px 0 8px;
    line-height: 36px;
}
