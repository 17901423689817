.amount-tx-container {
    display: flex;
    flex-direction: column;

}

.amount-controls {
    position: relative;
    width: 100%;

    .token-select {
        --control-text-weight: 600;

        flex: initial;
    }

    .currency-option.currency-option {
        min-width: 320px;
        padding-top: 4px;
        padding-bottom: 4px;
        height: 48px;
    }

    .currency-logo {
        border-radius: 10px;
        margin-right: 6px;
        object-fit: cover;
    }

    .currency-name-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .currency-network-domain {
            font-size: 12px;
            color: var(--text-color-secondary);
            line-height: 12px;
            margin-top: 2px;
        }
    }

    .currency-option-balance {
        flex: 1;
        text-align: right;
        margin-left: 24px;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .currency-option.currency-option:not(.disabled) .currency-option-balance {
        color: var(--text-color-secondary);
    }

    .amount-price {
        font-size: 12px;
        margin-left: 8px;
        font-weight: 600;

        &.above {
            position: absolute;
            top: -20px;
            right: 0;
        }
    }

    .amount-action {
        margin-left: 8px;
    }

    .input-loader {
        margin-left: 8px;
    }

    .common-tokens {
        position: sticky;
        top: var(--control-height);
        border-bottom: 1px solid var(--border-color-dark);
        padding: var(--control-padding);
        background: var(--black-dark);
        z-index: 10;
        overflow: scroll;
        display: flex;
        -ms-overflow-style: none;
        scrollbar-width: none;
        align-items: center;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.25);

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.selected-currency-balance {
    margin: 12px 0 32px;
    text-align: right;
    font-size: 14px;
    height: 18px;
    line-height: 18px;
    color: var(--text-color-secondary)
}

.transaction-bottom-space {
    display: block;
    height: 24px;
}
