.connected-wallet-menu.connected-wallet-menu {
    margin-top: 8px;
    width: 360px;
    max-height: initial;
    background-color: var(--background-color);

    .wallet-menu-header {
        padding: 8px;
        position: relative;
        border-bottom: 1px solid var(--border-color);

        .network-selector {
            .wallet-select-control, .network-select-control {
                --control-color-normal: var(--black-light);
                --control-color-hover: rgba(var(--black-light-rgb), 0.65);
                --control-color-active: rgba(var(--black-light-rgb), 0.65);

                border: none;
            }

            .wallet-select-control {
                border-left: 1px solid var(--border-color-dark);
            }
        }

        .wallet-menu-actions {
            position: absolute;
            right: 4px;
            top: calc(100% + 2px);
            display: flex;
            align-items: center;

            .refresh-button {
                margin-right: -4px;
                font-size: 16px;
                padding-top: 2px;
                font-weight: 500;
            }

            .quick-auth-button {

                &.connected {
                    --button-text-color: var(--orange);
                }

                &.loading {
                    --spinner-color: var(--text-color);
                    --button-text-color: rgba(var(--orange-rgb), 0.5);
                }
            }
        }
    }

    .wallet-account-tab {
        height: 280px;

        &.no-actions {
            height: 338px;
        }
    }

    .account-menu-actions {
        padding: 0 16px;
        height: 58px;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        display: flex;
        align-items: center;

        .account-menu-action {
            padding: 0;
            flex: 1;

            &:not(:last-of-type) {
                margin-right: 8px;
            }
        }
    }

    .wallet-menu-nav {
        display: block;
        background-color: var(--background-color-secondary);
        border-top: 1px solid var(--border-color);

        .nav-item-list {
            margin: 0;
        }

        .wallet-menu-nav-item {
            --link-color-normal: rgba(var(--cream-dark-rgb), 0.5);

            flex: 1;
            margin: 0;
            justify-content: center;
            display: flex;
            padding: 8px 4px;
        }
    }
}

.recover-portal-wallet-menu-header {
    padding: 16px;
    width: 100%;
    line-height: 24px;
    text-align: center;
    border-bottom: 1px solid var(--border-color);

    .recover-wallet-button {
        margin-top: 16px;
    }
}

