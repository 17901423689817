.nfts-container {
    border-top: 1px solid var(--border-color);
    padding: 6px;

    .nfts-loader {
        margin: 10px auto;
    }

    .no-nfts {
        text-align: center;
        display: block;
        padding: 16px 0;
        color: var(--text-color-secondary);
    }

    .nft-container {
        display: inline-block;
        width: calc(25% - 12px);
        position: relative;
        margin: 6px 6px 16px;
        cursor: pointer;

        img {
            width: 100%;
            border: 1px solid var(--cream-very-dark);
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }

        .nft-name {
            width: 100%;
            font-size: 12px;
        }
    }
}
