.footer {
    position: relative;
    min-height: var(--footer-height);
    display: flex;
    width: 100%;
    padding: var(--page-padding);
    justify-content: center;
    align-items: center;

    .full-logo {
        height: 24px;
        display: inline-block;
    }

    .footer-nav-bar {
        display: none;
        position: fixed;
        z-index: 11;
        height: var(--footer-height);
        bottom: 0;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        padding: 0 16px;
        border-top: 1px solid var(--border-color);
        background-color: var(--background-color);

        .nav-item-list {
            margin: 0;
            width: 100%;

            .footer-nav-item {
                padding: 4px 8px;
                border-radius: 20px;
                justify-content: center;
                white-space: nowrap;
                flex: 1;

                &.active {
                    background-color: rgba(var(--cream-rgb), 0.1);
                }
            }
        }
    }

    .nav-bar-more-menu {
        position: fixed;
        display: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 100%;
        bottom: 0;
        z-index: 10;
        border-bottom: none;
        border-width: 1px 0 0 0;
        padding-bottom: calc(var(--footer-height) + 16px);
        transform: translateY(100%);
        transition: transform 200ms ease-in-out;

        &.open {
            transform: translateY(0);
        }

        .more-menu-nav-items .nav-item-list {
            flex-direction: column;
            margin: 0;
            flex: 1;

            .more-menu-nav-item {
                margin: 0;
                height: 44px;
            }
        }

        .more-menu-social-links {
            margin-top: 16px;
            margin-left: -4px;
            border-left: none;
        }
    }
}

.social-links {
    display: flex;
    border-left: 1px solid var(--cream-dark);
    margin-left: 16px;

    .social-link {
        width: 20px;
        display: inline-block;
        margin-left: 16px;
    }
}

@media only screen and (max-width: 1024px) {
    .footer {
        .full-logo, .social-links {
            display: none;
        }

        .nav-bar {
            display: flex;
        }

        .nav-bar-more-menu {
            display: block;

            .social-links {
                display: flex;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .footer .footer-nav-bar {
        padding: 0 4px;
        font-size: 12px;

        .nav-item-list > * {
            margin: 0;

            .arrow-down {
                margin-left: 4px;
                width: 10px;
            }
        }
    }
}
