.rollapp-summary {
    display: flex;
    align-items: center;

    .network-logo {
        min-width: 42px;
        width: 42px;
        height: 42px;
        border-radius: 21px;
        margin-right: 10px;
        object-fit: cover;
    }

    .rollapp-details {
        display: flex;
        flex-direction: column;

        .rollapp-name-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .rollapp-name {
                font-size: 15px;
                max-width: 256px;
                overflow: hidden;
                font-weight: 500;
                color: var(--text-color);
                text-overflow: ellipsis;
                margin-right: 6px;

                &.clickable:hover {
                    text-decoration: underline;
                }
            }

            .rollapp-info {
                color: var(--text-color-secondary);
                margin: 4px 0;
                font-size: 12px;
                display: flex;
                white-space: nowrap;
                align-items: center;

                .status-indicator-container {
                    display: inline-block;
                    margin: 0 4px;
                }

                .fair-launch-tag {
                    --color: var(--orange);

                    width: 22px;
                    margin: -3px 0 -3px -4px;
                }
            }
        }

        .rollapp-alias {
            font-style: italic;
            width: fit-content;
            font-weight: 600;
            font-size: 12px;
            margin-top: -1px;
            color: var(--text-color-secondary);
        }
    }
}


@media only screen and (max-width: 1024px) {
    .rollapp-summary:not(.big) {
        .network-logo {
            width: 32px;
            height: 32px;
            min-width: 32px;
        }

        .rollapp-details {
            .rollapp-name-container {
                flex-direction: column;
                align-items: flex-start;

                .rollapp-name {
                    margin-right: 0;
                    font-size: 14px;
                }

                .rollapp-info {
                    margin: 4px 0 0;
                }
            }

            .rollapp-alias {
                display: none;
            }
        }
    }
}


@media only screen and (max-width: 720px) {
    .rollapp-summary:not(.big) .rollapp-details .rollapp-name-container .rollapp-name {
        max-width: 128px;
    }
}
