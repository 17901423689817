.account-nfts-amount {
    padding: 16px 4px 16px 16px;
    overflow: hidden;

    .property-label {
        font-size: 14px;
    }

    .property-value {
        font-size: 32px;
        flex-wrap: wrap;
        margin-top: 0;
        padding-top: 2px;
        height: initial;
        min-height: 42px;
        line-height: initial;
    }
}
